import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { Component, useState } from 'react';
import { MotoristasService } from '../../service/MotoristasService';
import UsuariosAtivos from './UsuariosAtivos';

export class Administracao extends Component {

  render() {
    return (
      <>
        <UsuariosAtivos/>
      </>
    )
  }

}