import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useState } from 'react';
import { MotoristasService } from '../../service/MotoristasService';
import { Loading } from '../Loading';

const motoristaService = new MotoristasService();

export default function MotoristaEdicao({ match }) {

    const [id, setId] = useState(match.params.id);
    const [motorista, setMotorista] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        motoristaService.getById(id).then(setMotorista);
    }, []);

    function submit(e) {
        setLoading(true);
        e.preventDefault();
        motoristaService.updateMotorista(id, motorista).then(() => {
            window.growl.showSuccess('Ok', 'Salvo com sucesso!');
            window.history.back();
        }).catch(e => {
            window.growl.showError('Erro', e.response.data.title);
        }).finally(() => {
            setLoading(false);
        });
    }

    function cancel() {
        window.history.back();
    }

    function resetarSenha() {
        const resetar = window.confirm('Essa ação resetará a senha do motorista para "123456".\nDeseja continuar?');
        if (resetar) {
            setLoading(true);
            motoristaService.resetPasswordMotorista(motorista.login).then(() => {
                window.growl.showSuccess('Ok', 'Alterado com sucesso!');
            }).catch(e => {
                window.growl.showError('Erro', e.response.data.title);
            }).finally(() => {
                setLoading(false);
            });
        }
    }

    if (!motorista || loading) return <Loading/>

    return (
        <div className="card card-w-title">
            <h1>Edição de motorista</h1>
            <form className="p-grid" onSubmit={submit}>
                <div className="p-col-12">
                    <div><label>Nome:</label></div>
                    <InputText
                        value={motorista.nome}
                        onChange={e => setMotorista({ ...motorista, nome: e.target.value })}
                        required/>
                </div>
                <div className="p-col-12">
                    <div><label>Email:</label></div>
                    <InputText
                        value={motorista.login}
                        onChange={e => setMotorista({ ...motorista, login: e.target.value })}
                        required/>
                </div>
                <div className="p-col-12">
                    <div><label>Telefone:</label></div>
                    <InputText
                        value={motorista.telefone}
                        onChange={e => setMotorista({ ...motorista, telefone: e.target.value })}
                        required/>
                </div>
                <div className="p-col-12">
                    <div><label>Empresa:</label></div>
                    <InputText
                        value={motorista.nomeEmpresa}
                        onChange={e => setMotorista({ ...motorista, nomeEmpresa: e.target.value })}/>
                </div>
                <div className="p-col p-justify-start">
                    <Button label="Salvar" style={{marginRight: "10px"}}/>
                    <Button label="Cancelar" type="button" style={{marginRight: "10px"}} onClick={cancel}/>
                    <Button label="Resetar senha" type="button" className="p-button-danger" onClick={resetarSenha}/>
                </div>
            </form>
        </div>
    );
}