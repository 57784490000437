import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { HashRouter } from 'react-router-dom'
import ScrollToTop from './ScrollToTop';
import { LoginPage } from './components/LoginPage';
import { isAuthenticated } from './service/AuthService';

ReactDOM.render(
    <HashRouter>
        <ScrollToTop>
            {!isAuthenticated() ? <LoginPage/> : <App></App>}            
        </ScrollToTop>
    </HashRouter>,
    document.getElementById('root')
);