import React, { useState, useEffect } from 'react';
import { Button } from "primereact/button";
import { Loading } from "../Loading";
import { Dialog } from 'primereact/dialog';
import { LeiturasService } from '../../service/LeiturasService';
import { getAlerts } from '../../service/LeiturasService';

const leiturasService = new LeiturasService();

export const DialogLeitura = React.memo(({ leitura, onHide }) => {

    if (!leitura) {
        return <div></div>
    }
  
  function hide(e) {
    onHide(e);
  }

  const footer = (
    <div>
        <Button label="Ok" icon="pi pi-check" onClick={onHide} />
    </div>
  );

  const Body = () => {
    const alerts = getAlerts(leitura);

    if (!alerts.length) {
        return <div>Sem alertas</div>
    }

    return (
        alerts.map(alert => {
            return (
                <div key={alert} className="alert-box">{alert}</div>
            );
        })
    );
  }

  const ExtraInfo = () => {
    const lat = leitura.latitude;
    const lon = leitura.longitude;
    const vel = leitura.velocidade;

    if (!lat || !lon || !lat.length || !lon.length) {
      return <span></span>;
    }

    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <i className="pi pi-map-marker"></i>
        <span style={{ fontSize: '10px' }}>{lat}, {lon}</span>
        <i className="pi pi-angle-double-right"></i>
        <span style={{ fontSize: '10px' }}>{vel || 'N/a'}</span>
      </div>
    );
  }

  return (
    <Dialog header={leitura.horaLeitura} footer={footer} visible={!!leitura} style={{width: '50vw'}} modal={true} onHide={hide}>
      <ExtraInfo/>
      <div style={{ lineBreak: 'anywhere' }}>
        <Body/>
      </div>
    </Dialog>
  )

});