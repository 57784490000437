import React, { Component } from 'react';
import { Loading } from '../Loading';
import { Dropdown } from 'primereact/dropdown';
import { EmpresasService } from '../../service/EmpresasService';
import queryString from 'query-string'

export class DropdownEmpresas extends Component {

  constructor(props) {
    super(props);
    this.state = { empresa: props.empresa };    
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search);
    const valorInicial = values.empresa;    

    this.empresasService = new EmpresasService();
    this.empresasService.getEmpresasAtivas().then(e => {
      this.setState({ empresas: e });
      return e;
    }).then(e => {      
      e.forEach(emp => {        
        if (emp.id + "" === valorInicial) {          
          this.setState({ empresa: emp });
        }
      });
    });
  }

  onChange(event) {    
    this.setState({ empresa: event.target.value })
    this.props.onChange(event.target.value);
  }

  render() {
    if (!this.state.empresas) {
      return <Loading isComponent={true}/>
    }
    return <Dropdown id={this.props.id} name={this.props.name} optionLabel="nome" value={this.state.empresa} options={this.state.empresas} onChange={this.onChange.bind(this)} placeholder="Selecione um Empresa" required/>
  }

}