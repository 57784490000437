import React, { Component } from 'react';
import { Loading } from '../Loading';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { MaquinasService } from '../../service/MaquinasService';
import {InputTextarea} from 'primereact/inputtextarea';
import { DropdownEmpresas } from '../empresas/DropdownEmpresas';

export class MaquinaEdicao extends Component {

  constructor() {
    super();
    this.state = {};
    this.maquinasService = new MaquinasService();
  }

  componentDidMount() {    
    const { match: { params } } = this.props;    
    if (params.id === "0") {
      let maquina = {
        empresa: {id: null},
        descricao: "Nova Maquina",
        comentarios: "",
        apiToken: "",
        ativo: true,
      };      
      this.setState({ maquina: maquina });      
    } else {      
      this.maquinasService.getMaquina(params.id)
        .then(data => this.setState({ maquina: data }));
    }        
  }  

  handleEditChange(event) {    
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;    
    const name = target.name;    
    let maquina = this.state.maquina;    
    maquina[name] = value;
    this.setState({maquina: maquina});
  }

  handleSubmit(event) {    
    this.maquinasService.saveMaquina(this.state.maquina).then(r => {
        setTimeout(() => {
            window.growl.showSuccess('Ok', 'Salvo com sucesso!');
            window.history.back();
        }, 500);
    }).catch(err => {
        console.log(err);
        window.growl.showError('Erro', err.response.data.title);
    });
    event.preventDefault();    
  }

  setEmpresa(empresa) {
    let maquina = this.state.maquina;
    maquina.empresa = empresa;
    this.setState({ maquina: maquina });     
  }

  handleCancel() {
    window.history.back();    
  }

  getTitle() {
    if (this.state.maquina.id) {
      return "Edição de Veículos";
    } else {
      return "Novo Veículo";
    }
  }

  render() {
    if (this.state.maquina === undefined) {
      return <Loading/>
    }
    return (
      <div className="card card-w-title">    
        <h1> {this.getTitle()} </h1>    
        <form className="p-grid" onSubmit={this.handleSubmit.bind(this)}>
            <div className="p-col-12">
              <div><label htmlFor="maquina-descricao"> Descrição: </label></div>
              <InputText id="maquina-descricao" name="descricao" value={this.state.maquina.descricao} onChange={this.handleEditChange.bind(this)} required/>          
            </div>
            <div className="p-col-12">
              <div><label htmlFor="maquina-descricao"> Token: </label></div>
              <InputText id="maquina-token" name="apiToken" value={this.state.maquina.apiToken} onChange={this.handleEditChange.bind(this)} required/>          
            </div>
            <div className="p-col-12">
              <div><label htmlFor="maquina-descricao"> Comenários: </label></div>
              <InputTextarea id="maquina-comentarios" name="comentarios" rows={5} cols={30} value={this.state.maquina.comentarios} onChange={this.handleEditChange.bind(this)} />
            </div>
            <div className="p-col-12">              
              <div><label htmlFor="maquina-empresa"> Empresa: </label></div>
              <DropdownEmpresas id="maquina-empresa" name="empresa.id" empresa={this.state.maquina.empresa} onChange={this.setEmpresa.bind(this)} location={this.props.location}/>
            </div>
            <div className="p-col-12">              
              <Checkbox id="maquina-ativo" name="ativo" checked={this.state.maquina.ativo} onChange={this.handleEditChange.bind(this)}/>
              <label htmlFor="maquina-ativo"> Ativo </label>
            </div>
            <div className="p-col p-justify-start">
              <Button label="Salvar" style={{marginRight: "10px"}}/>
              <Button label="Cancelar" type="button" onClick={this.handleCancel.bind(this)}/>                                      
            </div>
        </form>        
      </div>
    )
  }
}