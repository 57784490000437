import axios from "axios";

export const TOKEN_KEY = "@eap-api-token";
export const NIVEL_KEY = "@eap-api-nivel";
export const NOME_KEY = "@eap-api-nome";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;
export const getToken = () => localStorage.getItem(TOKEN_KEY);
export const getNivel = () => localStorage.getItem(NIVEL_KEY);
export const getNome = () => localStorage.getItem(NOME_KEY);

export const login = (login, senha) => {
    return new Promise((resolve, reject) => {
        axios.post('api/login', { login: login, senha: senha })
        .then(res => {            
            localStorage.setItem(TOKEN_KEY, res.data.jwt.jwt);
            localStorage.setItem(NIVEL_KEY, res.data.usuario.nivel);          
            localStorage.setItem(NOME_KEY, res.data.usuario.nome);
            document.location.reload();
            resolve();
        }).catch(err => {
            reject();
            alert('Não foi possível acessar. Verifique suas credenciais');
        });
    });
};

export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(NIVEL_KEY);
    localStorage.removeItem(NOME_KEY);
    document.location.reload();
};