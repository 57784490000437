import React from 'react';
import { Card } from 'primereact/card';
import { getAlerts } from '../../service/LeiturasService';
import Moment from 'moment';

export function CardMonitoramento(props) {

    const leitura = props.dados.leitura;
    let status = <div></div>;
    let manual = <div></div>;

    if (Moment.duration(Moment(leitura.horaLeitura).diff(Moment())).asHours() > -1) {
        if (leitura.realizandoEntrega) {
            status = <div className="in-deliver">Em Entrega</div>;
        } else if (leitura.viagemIniciada) {
            status = <div className="in-travel">Em viagem</div>;
        }
        if (leitura.modoManual) {
            manual = <div className="man">Manual</div>;
        }
    }

    if (leitura.versao === 2) return (
        <div className="p-col-12 p-md-3 p-lg-3" onClick={() => onCardClick(props.dados.idMaquina)}>
            <Card className="monitoring-card">
                <div className="p-card-title">
                    <div>{props.dados.nomeMaquina}</div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        {status}
                        {manual}
                    </div>
                </div>
                <div className="p-card-subtitle">{props.dados.nomeEmpresa}</div>
                <div className="data">{Moment(leitura.horaLeitura).format('DD/MM/YYYY HH:mm:ss')}</div>
                <div className="p-grid p-nogutter info-container">
                    <InfoBig title="Temperatura">
                        {leitura.temperaturaMedia}°
                    </InfoBig>
                    <InfoSmall title="T1">
                        {leitura.temperatura1}°
                    </InfoSmall>
                    <InfoSmall title="T2">
                        {leitura.temperatura2}°
                    </InfoSmall>
                    <InfoSmall title="SP">
                        {leitura.setpointTemperatura}°
                    </InfoSmall>
                    <InfoSmall title="TEXT">
                        {leitura.temperaturaExterna}°
                    </InfoSmall>
                </div>
                <div className="p-grid p-nogutter info-container">
                    <InfoBig title="Umidade">
                        {leitura.umidadeMedia}%
                    </InfoBig>
                    <InfoSmall title="UmA">
                        {leitura.umidade1}%
                    </InfoSmall>
                    <InfoSmall title="UmB">
                        {leitura.umidade2}%
                    </InfoSmall>
                    <InfoSmall title="SP">
                        {leitura.setpointUmidade}%
                    </InfoSmall>
                </div>
                <div className="p-grid p-nogutter info-container">
                    <InfoBig title="Co2">
                        {leitura.co2Media}%
                    </InfoBig>
                    <InfoSmall title="Co2A">
                        {leitura.co2A}%
                    </InfoSmall>
                    <InfoSmall title="Co2B">
                        {leitura.co2B}%
                    </InfoSmall>
                    <InfoSmall title="SP">
                        {leitura.setpointCo2}%
                    </InfoSmall>
                </div>
                <Alerts leitura={leitura}/>
            </Card>
        </div>
    );

    return (
        <div className="p-col-12 p-md-3 p-lg-3" onClick={(e) => onCardClick(props.dados.idMaquina)}>
            <Card className="monitoring-card">
                <div className="p-card-title">
                    <div>{props.dados.nomeMaquina}</div>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        {manual}
                    </div>
                </div>
                <div className="p-card-subtitle">{props.dados.nomeEmpresa}</div>
                <div className="data">{Moment(leitura.horaLeitura).format('DD/MM/YYYY HH:mm:ss')}</div>
                <div className="p-grid p-nogutter info-container">
                    <InfoBig title="Temperatura">
                        {leitura.temperaturaMedia}°
                    </InfoBig>
                    <InfoSmall title="T1">
                        {leitura.temperatura1}°
                    </InfoSmall>
                    <InfoSmall title="T2">
                        {leitura.temperatura2}°
                    </InfoSmall>
                    <InfoSmall title="SP">
                        {leitura.setpointTemperatura}°
                    </InfoSmall>
                    <InfoSmall title="TEXT">
                        {leitura.temperaturaExterna}°
                    </InfoSmall>
                </div>
                <div className="p-grid p-nogutter info-container">
                    <InfoBig title="Umidade">
                        {leitura.umidadeMedia}%
                    </InfoBig>
                    <InfoSmall title="Um">
                        {leitura.umidadeMedia}%
                    </InfoSmall>
                    <InfoSmall title="SP">
                        {leitura.setpointUmidade}%
                    </InfoSmall>
                </div>
                <div className="p-grid p-nogutter info-container">
                    <InfoBig title="Co2">
                        {leitura.co2Media}%
                    </InfoBig>
                    <InfoSmall title="Co2">
                        {leitura.co2Media}%
                    </InfoSmall>
                    <InfoSmall title="SP">
                        {leitura.setpointCo2}%
                    </InfoSmall>
                </div>
                <Alerts leitura={leitura}/>
            </Card>
        </div>
    )

}

function InfoSmall(props) {
    return (
        <div className="p-col info-small">
            <div className="title">
                {props.title}
            </div>
            <div className="value">
                {props.children}
            </div>
        </div>
    );
}

function InfoBig(props) {
    return (
        <div className="p-col-12 p-nogutter info-big">
            <div className="value">
                {props.children}
            </div>
            <div className="title">
                {props.title}
            </div>
        </div>
    );
}

function onCardClick(idMaquina) {
    window.location = "/#/maquina-resumo/" + idMaquina;
}

function Alerts(props) {
    const alerts = getAlerts(props.leitura);

    if (!alerts.length) {
        return <div></div>
    }

    return (
        alerts.map(alert => {
            return (
                <div key={alert} className="alert-box">{alert}</div>
            );
        })
    );
}