import React, {Component} from 'react';
import classNames from 'classnames';
import {AppTopbar} from './AppTopbar';
import {AppMenu} from './AppMenu';
import {AppProfile} from './AppProfile';
import {Route} from 'react-router-dom';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import '@fullcalendar/core/main.css';
import '@fullcalendar/daygrid/main.css';
import '@fullcalendar/timegrid/main.css';
import './layout/layout.scss';
import './App.scss';
import Index from './components/inicio/Index';
import { Empresas } from './components/empresas/Empresas';
import { EmpresaEdicao } from './components/empresas/EmpresaEdicao';
import { Growl } from 'primereact/growl';
import { GrowlService } from './service/GrowlService'
import { Maquinas } from './components/maquinas/Maquinas';
import { MaquinaEdicao } from './components/maquinas/MaquinaEdicao';
import { Usuarios } from './components/usuarios/Usuarios';
import { UsuarioEdicao } from './components/usuarios/UsuarioEdicao';
import { Administracao } from './components/administracao/Administracao';
import { WebSocketHelper } from './service/WebSocketHelper';
import { logout, getNivel, getToken } from './service/AuthService'
import { SetpointEdicao } from './components/maquinas/SetpointEdicao';
import { MaquinaResumo } from './components/resumo-veiculo/MaquinaResumo';
import Motoristas from './components/motoristas/Motoristas';
import MotoristaEdicao from './components/motoristas/MotoristaEdicao';

class App extends Component {

    constructor() {
        super();
        this.state = {
            layoutMode: 'static',
            layoutColorMode: 'dark',
            staticMenuInactive: false,
            overlayMenuActive: false,
            mobileMenuActive: false
        };               
                
        //window.wsLeitura = new WebSocketHelper(`ws://${window.location.host}/ws/ws-leituras`);
        //window.ws = new WebSocketHelper(`ws://${window.location.host}/ws/?token=${getToken()}`);

        this.onWrapperClick = this.onWrapperClick.bind(this);
        this.onToggleMenu = this.onToggleMenu.bind(this);
        this.onSidebarClick = this.onSidebarClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.createMenu();
    }

    onWrapperClick(event) {
        if (!this.menuClick) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            });
        }

        this.menuClick = false;
    }

    onToggleMenu(event) {
        this.menuClick = true;

        if (this.isDesktop()) {
            if (this.state.layoutMode === 'overlay') {
                this.setState({
                    overlayMenuActive: !this.state.overlayMenuActive
                });
            }
            else if (this.state.layoutMode === 'static') {
                this.setState({
                    staticMenuInactive: !this.state.staticMenuInactive
                });
            }
        }
        else {
            const mobileMenuActive = this.state.mobileMenuActive;
            this.setState({
                mobileMenuActive: !mobileMenuActive
            });
        }
       
        event.preventDefault();
    }

    onSidebarClick(event) {
        this.menuClick = true;
    }

    onMenuItemClick(event) {
        if(!event.item.items) {
            this.setState({
                overlayMenuActive: false,
                mobileMenuActive: false
            })
        }
    }

    createMenu() {
      if (getNivel() === 'MASTER') {
        this.menu = [
          {label: 'Início', icon: 'pi pi-fw pi-home',  command: () => {window.location = '#/'}},            
          {label: 'Veículos', icon: 'pi pi-fw pi-wifi', command: () => {window.location = "#/maquinas"}},
          {label: 'Empresas', icon: 'pi pi-briefcase', command: () => {window.location = "#/empresas"}},
          {label: 'Usuários', icon: 'pi pi-user', command: () => {window.location = "#/usuarios"}},
          {label: 'Motoristas', icon: 'pi pi-users', command: () => {window.location = "#/motoristas"}},
          {label: 'Administração', icon: 'pi pi-cog', command: () => {window.location = "#/administracao"}},
          {label: 'Sair', icon: 'pi pi-times', command: () => {logout()}}
        ];
      } else {
        this.menu = [
          {label: 'Início', icon: 'pi pi-fw pi-home',  command: () => {window.location = '#/'}},          
          {label: 'Sair', icon: 'pi pi-times', command: () => {logout()}}
        ];
      }        
    }

    addClass(element, className) {
        if (element.classList)
            element.classList.add(className);
        else
            element.className += ' ' + className;
    }

    removeClass(element, className) {
        if (element.classList)
            element.classList.remove(className);
        else
            element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    componentDidUpdate() {
        if (this.state.mobileMenuActive)
            this.addClass(document.body, 'body-overflow-hidden');
        else
            this.removeClass(document.body, 'body-overflow-hidden');
    }

    isExportLocation() {
      return (window.location + '').includes('/export');
    }

    render() {

        const wrapperClass = classNames('layout-wrapper', {
            'layout-overlay': this.state.layoutMode === 'overlay',
            'layout-static': this.state.layoutMode === 'static',
            'layout-static-sidebar-inactive': this.state.staticMenuInactive && this.state.layoutMode === 'static',
            'layout-overlay-sidebar-active': this.state.overlayMenuActive && this.state.layoutMode === 'overlay',
            'layout-mobile-sidebar-active': this.state.mobileMenuActive
        });

        const sidebarClassName = classNames("layout-sidebar", {
            'layout-sidebar-dark': true,
        });

        if (this.isExportLocation()) {
          return (
            <Route path="/maquina-resumo/:id/export/:ini/:fim" component={MaquinaResumo} />
          );
        }

        return (            
            <div className={wrapperClass}>
                <Growl ref={(el) => window.growl = new GrowlService(el)}/>
                <AppTopbar onToggleMenu={this.onToggleMenu}/>

                <div ref={(el) => this.sidebar = el} className={sidebarClassName} onClick={this.onSidebarClick}>
                    <AppProfile />
                    <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} />
                </div>

                <div className="layout-main">                           
                  <Route path="/" exact component={Index} />
                  <Route path="/empresas" component={Empresas} />
                  <Route path="/empresa/:id" component={EmpresaEdicao} />                  
                  <Route path="/maquinas" component={Maquinas} />
                  <Route path="/maquina/:id" component={MaquinaEdicao} />
                  <Route path="/maquina-resumo/:id" component={MaquinaResumo} />
                  <Route path="/setpoint/:idMaquina" component={SetpointEdicao} />
                  <Route path="/usuarios/" component={Usuarios} />
                  <Route path="/usuario/:id" component={UsuarioEdicao} />
                  <Route path="/administracao" component={Administracao} />
                  <Route path="/motoristas" exact component={Motoristas} />
                  <Route path="/motoristas/:id" exact component={MotoristaEdicao} />
                </div>
                <div className="layout-mask"></div>
            </div>
        );
    }
}

export default App;
