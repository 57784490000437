import React, { Component } from 'react';
import { UsuariosService } from '../../service/UsuariosService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Loading } from '../Loading';

export class Usuarios extends Component {

  constructor() {
    super();
    this.state = {
      mostrarInativos: false,
    };
    this.usuariosService = new UsuariosService();
  }

  componentDidMount() {
    let consulta = this.usuariosService.getUsuarios();    
    consulta      
      .then(data => data.sort((a, b) => a.id - b.id))
      .then(data => data.sort((a, b) => b.ativo - a.ativo))
      .then(data => this.setState({ usuarios: data }))      
  }

  lineOptions(rowData, column) {
    return <Button icon="pi pi-pencil" onClick={() => window.location = `#/usuario/${rowData.id}`}/>
  }

  lineStatus(rowData, column) {    
    if (rowData.ativo) {
      return <span> Ativo </span>
    }
    return <span style={{color: 'red'}} > Inativo </span>
  }

  handleMostrarInativos(event) {    
    this.setState({ mostrarInativos: event.checked });
  }

  getFilteredUsuarios() {
    return this.state.usuarios.filter(e => e.ativo || this.state.mostrarInativos);
  }

  render() {    
    const pathNovo = '#/usuario/0';
    
    if (this.state.usuarios === undefined) {
      return <Loading/>
    }

    return (
      <div>
          <DataTable value={this.getFilteredUsuarios()} responsive={false} header="Usuários">          
            <Column field="nome" header="Nome" sortable={true}/>                   
            <Column field="empresa.nome" header="Empresa" sortable={true}/>          
            <Column field="nivel" header="Nível" sortable={true}/>                   
            <Column header="Status" body={this.lineStatus} style={{textAlign: 'center'}}/>
            <Column header="Opções" body={this.lineOptions} style={{textAlign: 'center'}}/>
          </DataTable>
          <div style={{display: 'flex', justifyContent: 'space-between', padding: '10px 0px'}}>
            <div>
              <Checkbox checked={this.state.mostrarInativos} onChange={this.handleMostrarInativos.bind(this)}/>
              <label htmlFor="maquina-nome"> Mostrar inativos </label>
            </div>
            <Button label="Novo" icon="pi pi-plus" onClick={() => window.location = pathNovo}/>
          </div>
      </div>
    );
  }
}