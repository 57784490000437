import React, { useState, useEffect } from 'react';
import { UsuariosService } from '../../service/UsuariosService'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import Moment from 'moment';
import { Button } from 'primereact/button';

const usuariosService = new UsuariosService();

export default function UsuariosAtivos() {

  const [usuarios, setUsuarios] = useState(null);

  useEffect(() => {
    buscarUsuarios();
  }, [])

  function buscarUsuarios() {
    usuariosService.getHistorico().then(historico => {
      historico.sort((a, b) => b.data - a.data).forEach(h => {
        h.data = formatarData(h.data);
      });
      setUsuarios(historico);
    });
  }

  const header = <div className="p-clearfix" style={{'lineHeight':'1.87em'}}>Histórico<Button icon="pi pi-refresh" style={{'float':'right'}} onClick={buscarUsuarios}/></div>;

  return (
    <div>
      <DataTable value={usuarios} header={header} responsive={true}>
        <Column field="nome" header="Nome" sortable={false}/>
        <Column field="usuario" header="Login" sortable={false}/>
        <Column field="empresa" header="Empresa" sortable={false}/>
        <Column field="data" header="Data"  sortable={false}/>
      </DataTable>
    </div>
  )
}

function formatarData(valor) {
  let tempo = new Date(valor);
  return Moment(tempo).format('DD/MM/YY HH:mm');
}