import React, { Component } from 'react';
import { Loading } from '../Loading';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { SetpointService } from '../../service/SetpointService';

export class SetpointEdicao extends Component {

  constructor() {
    super();
    this.state = {};    
    this.setpointService = new SetpointService();
  }

  componentDidMount() {    
    const { match: { params } } = this.props;    
    this.idMaquina = params.idMaquina;  
    this.setpointService.getSetpointPorMaquina(this.idMaquina)
      .then(data => this.setState({ setpoint: data }));  
  }  

  handleEditChange(event) {    
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;    
    const name = target.name;    
    let setpoint = this.state.setpoint;
    setpoint[name] = value;
    this.setState({setpoint: setpoint});
  }

  handleSubmit(event) {    
    this.setpointService.saveSetpoint(this.state.setpoint, this.idMaquina)
    .then(sucesso => {
      if (sucesso) {      
        setTimeout(() => {
          window.history.back();
        }, 500);
      }
    });
    event.preventDefault();    
  }

  handleCancel() {
    window.history.back();    
  }

  getTitle() {
    return "Setpoints"
  }

  render() {
    if (this.state.setpoint === undefined) {
      return <Loading/>
    }
    return (      
      <div className="card card-w-title">
        <h1> {this.getTitle()} </h1>    
        <form className="p-grid" onSubmit={this.handleSubmit.bind(this)}>
            <div className="p-col-12">
              <div><label htmlFor="setpoint-temperatura"> Temperatura: </label></div>
              <InputText type="number" id="setpoint-temperatura" name="temperatura" value={this.state.setpoint.temperatura} onChange={this.handleEditChange.bind(this)} required/>
            </div>
            <div className="p-col-12">
              <div><label htmlFor="setpoint-humidade"> Humidade: </label></div>
              <InputText type="number" id="setpoint-humidade" name="humidade" value={this.state.setpoint.humidade} onChange={this.handleEditChange.bind(this)} required/>
            </div>
            <div className="p-col-12">
              <div><label htmlFor="setpoint-co2"> Co2: </label></div>
              <InputText type="number" id="setpoint-co2" name="co2" value={this.state.setpoint.co2} onChange={this.handleEditChange.bind(this)} required/>
            </div>
            <div className="p-col p-justify-start">
              <Button label="Salvar" style={{marginRight: "10px"}}/>
              <Button label="Cancelar" type="button" onClick={this.handleCancel.bind(this)}/>                                      
            </div>
        </form>                        
      </div>      
    )
  }
}