import api from './Api';

export class SetpointService {

  getSetpointPorMaquina(idMaquina) {
    return api.get(`/api/setpoints/maquina/${idMaquina}`)
      .then(res => res.data);
  }

  saveSetpoint(setpoint, idMaquina) {
    return api.post(`/api/setpoints/maquina/${idMaquina}`, setpoint)
      .then(res => {
        if (res.status === 200) {
          window.growl.showSuccess('Ok', 'Salvo com sucesso!');
        }
        return true;
      }).catch(err => {
        window.growl.showError('Erro', 'Ocorreu um erro ao salvar');
        console.log(err);
        return false;
      });
  }  

}