import { Dialog } from 'primereact/dialog';
import { GMap } from 'primereact/gmap';
import React, { useEffect, useState } from 'react';

let mapObj;
const marcadores = [];

export default function Mapa({ leituras }) {

    useEffect(() => {
        if (mapObj) {
            leituras.filter(l => l.leitura.latitude && l.leitura.longitude).forEach(l => {
                marcadores.filter(m => m.idMaquina === l.idMaquina).forEach(m => {
                    let lat = Number(l.leitura.latitude);
                    let lng = Number(l.leitura.longitude);
                    m.setPosition({lat: lat, lng: lng});
                });
            });
        }
    }, [leituras]);

    function mapReady(obj) {
        if (!window.google) return;
        if (!mapObj) mapObj = obj;
        let map = obj.map;                  
        let bounds = new window.google.maps.LatLngBounds();
        leituras.filter(l => l.leitura.latitude && l.leitura.longitude).forEach(l => {      
            let lat = Number(l.leitura.latitude);
            let lng = Number(l.leitura.longitude);
            let marker = new window.google.maps.Marker({
                position: {lat: lat, lng: lng}, 
                title: l.nomeMaquina,
                map: map
            });

            marker.idMaquina = l.idMaquina;

            window.google.maps.event.addListener(marker, 'click', () => {
                marker.infoWindow.open(map, marker);
            });

            let infoWindow = new window.google.maps.InfoWindow({
                pixelOffset: new window.google.maps.Size(0, 44),
                disableAutoPan: true
            });
            infoWindow.setContent(
                '<div>' + 
                    '<strong>' + l.nomeMaquina + '</strong>' +
                    '<div> Temp: ' + l.leitura.temperaturaMedia + '°</div>' +
                '</div>'
            );
            infoWindow.open(map, marker);
            marker.infoWindow = infoWindow;

            marcadores.push(marker);

            let latLng = new window.google.maps.LatLng(lat, lng);
            bounds.extend(latLng);
        });

        map.fitBounds(bounds);
    }

    function overlayclick(event) {
        event.overlay.infoWindow.open(event.map, event.overlay);
        event.map.setCenter(event.overlay.getPosition());
    }

    return (
        <GMap
            onMapReady={mapReady}
            options={{ zoom: 12 }} 
            style={{width: '100%', minHeight: '320px'}}
            onOverlayClick={overlayclick}>
        </GMap>
    );
}