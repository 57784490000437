import React, { useEffect, useState } from 'react';
import { LeiturasService } from '../../service/LeiturasService';
import { Loading } from '../Loading';
import Mapa from './Mapa';
import TabelaMonitoramento from './TabelaMonitoramento';

const leiturasService = new LeiturasService();
let timmerRefresh;

export default function Index() {

  const [leituras, setLeituras] = useState(leituras);
  const [possuiGps, setPossuiGps] = useState(false);

  useEffect(() => {
    consultarInformacoes();
    timmerRefresh = setInterval(() => {
      consultarInformacoes();
    }, 60000);
    return () => {
      clearInterval(timmerRefresh);
    }
  }, []);

  function consultarInformacoes() {
    leiturasService.getUltimasLeituras().then(leitura => {
      setLeituras(leitura);
      setPossuiGps(leitura.some(l => l.leitura.latitude && l.leitura.longitude));
    });
  }

  if (!leituras) {
    return <Loading/>
  }

  return (
    <div>
      <div className="p-grid">
        {possuiGps ? (<div className="p-col-12">
          <Mapa leituras={leituras}/>
        </div>) : <span/>}
        <div className="p-col-12">
          <TabelaMonitoramento leituras={leituras}/>
        </div>
      </div>      
    </div>
  );

}