import React, { Component } from 'react';
import {Card} from 'primereact/card';
import { InputText } from 'primereact/inputtext';
import {Password} from 'primereact/password';
import {Button} from 'primereact/button';
import banner from '../img/automa.png'
import { login } from '../service/AuthService';

export class LoginPage extends Component {

  constructor() {
    super();
    this.state = {
      usuario: "",
      senha: "",
      loading: false,
    }
  }

  loginSubmit(event) {
    this.setState({ loading: true });
    login(this.state.usuario, this.state.senha).then(() => {
        this.setState({ loading: false });
    }).catch(() => {
        this.setState({ loading: false });
    });
    event.preventDefault();
  }

  render() {
    const header = (        
        <img alt="Card" src={banner}/>
    );
    return (
      <div style={{ backgroundColor: '#004f7a', height: '100%' }}> 
        <div style={{ height: '100%' }} className="p-grid p-justify-center p-align-center p-nogutter">
          <div className="p-sm-9 p-md-6 p-lg-4">
            <Card 
              title="Bem-vindo!" 
              subTitle="Forneça suas credenciais para ver suas informações"
              header={header}>
              <form className="p-grid" onSubmit={this.loginSubmit.bind(this)}>
                <div className="p-col-12 p-float-label">                  
                  <InputText 
                    name="usuario" 
                    type="text" 
                    size="30" 
                    value={this.state.usuario} 
                    onChange={(e) => this.setState({usuario: e.target.value})} 
                    style={{ width: '100%' }}
                    placeholder="Usuário"
                    required/>
                </div>
                <div className="p-col-12">                  
                  <Password 
                    name="senha" 
                    type="text" 
                    size="30" 
                    value={this.state.senha} 
                    onChange={(e) => this.setState({senha: e.target.value})} 
                    style={{ width: '100%' }}
                    placeholder="Senha"  
                    feedback={false}                  
                    required/>
                </div>
                {this.state.loading ? buttonLoading() : buttonLogin()}
              </form>
            </Card>
          </div>          
        </div>
      </div>
    );
  }

}

const buttonLogin = () => {
    return (
        <div className="p-grid p-nogutter p-col p-justify-left">
            <Button className="p-col" label="Entrar"/>
        </div>
    )
}

const buttonLoading = () => {
    return (
        <div className="p-grid p-nogutter p-col p-justify-left">
            <Button disabled={true} className="p-col" label="Entrando..."/>
        </div>
    )
}