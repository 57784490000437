import api from './Api';

export class MaquinasService {

  getMaquinas() {
    return api.get('api/maquinas')
      .then(res => res.data);
  }

  getMaquina(id) {
    return api.get(`api/maquinas/${id}`)
      .then(res => res.data);
  }

  getMaquinasPorEmpresa(idEmpresa) {
    return api.get(`api/maquinas?id_empresa=${idEmpresa}`)
      .then(res => res.data);
  }

  saveMaquina(maquina) {
      return new Promise((resolve, reject) => {
        api.post('api/maquinas', maquina).then(res => {
            if (res.status === 200) {
                resolve();
            } else {
                reject(res);
            }
          }).catch(err => {
            window.growl.showError('Erro', 'Ocorreu um erro ao salvar');
            console.log(err);
          });
      });
  }  

}