import axios from "axios";
import { getToken, logout } from "./AuthService";

const api = axios.create({
  baseURL: "/"
});

api.interceptors.request.use(async config => {
  const token = getToken();
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if (error.response.data.title && error.response.data.title.search('Token') > -1) {
    logout();
  }
  return Promise.reject(error);
});

export default api;