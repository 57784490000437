import api from './Api';

export class EmpresasService {

  getEmpresas() {
    return api.get('api/empresas')
      .then(res => res.data);
  }

  getEmpresasAtivas() {
    return api.get('api/empresas?ativo=true')
      .then(res => res.data);
  }

  getEmpresa(id) {
    return api.get(`api/empresas/${id}`)
      .then(res => res.data);
  }

  saveEmpresa(empresa, emails) {
    return api.post('api/empresas', empresa)
      .then(res => {
        if (res.status === 200) {
          api.post(`api/empresas/${res.data.id}/emails`, emails).then(r => {
            window.growl.showSuccess('Ok', 'Salvo com sucesso!');
          });
        }
        return true;
      }).catch(err => {        
        window.growl.showError('Erro', 'Ocorreu um erro ao salvar');        
        return false;
      });
  }

  getEmails(id) {
      return api.get(`api/empresas/${id}/emails`)
        .then(res => res.data);
  }

}