import api from './Api';
import Moment from 'moment';
import { Observable } from 'rxjs';
import { getToken } from './AuthService'
export class LeiturasService {
  
  getLeituras() {
    return api.get('api/leituras')
      .then(res => res.data);
  }

  getLeitura(id) {
    return api.get('api/leituras/' + id)
      .then(res => res.data);
  }

  getUltimasLeituras() {
    return api.get('api/leituras/ultimas-leituras')
      .then(res => res.data);
  }    

  getResumoDiario(idMaquina) {
    return api.get('/api/leituras/resumo-diario/' + idMaquina)
      .then(res => res.data);
  }

  getResumoPeriodo(idMaquina, dataInicial, dataFinal) {
    let query = "?";

    if (dataInicial) {
      query += `dataInicial=${Moment(dataInicial).format('YYYY-MM-DD HH:mm:00')}&`;
    }

    if (dataFinal) {
      query += `dataFinal=${Moment(dataFinal).format('YYYY-MM-DD HH:mm:59')}&`;
    }
    
    return api.get(`/api/maquinas/${idMaquina}/leituras${query}`)
    //return api.get(`/api/leituras/resumo-periodo/${idMaquina}${query}`)
      .then(res => res.data);
  }

}

export const sseNewObservable = new Observable(sub => {
    if (!window.sseLeitura) {
        window.sseLeitura = new EventSource(`http://localhost:7000/sse/leituras/${getToken()}`);
    }
    window.sseLeitura.addEventListener("new", msg => sub.next(msg));
});

export function hasAlert(reading) {
    let a = getAlerts(reading).length > 0;
    return a;
}

export function getAlerts(reading) {
    const alerts = []

    if (reading.alarmeErroSensorTemperatura) {
        alerts.push("ERRO SENS TEMP");
    }
    if (reading.alarmeErroSensorUmidade) {
        alerts.push("ERRO SENS UMID");
    }
    if (reading.alarmeErroSensorCo2) {
        alerts.push("ERRO SENS CO2");
    }
    if (reading.alarmeAltaTemperatura) {
        alerts.push("ALTA TEMPERATURA");
    }
    if (reading.alarmeBaixaTemperatura) {
        alerts.push("BAIXA TEMPERATURA");
    }
    if (reading.alarmeAltaUmidade) {
        alerts.push("ALTA UMIDADE");
    }
    if (reading.alarmeBaixaUmidade) {
        alerts.push("BAIXA UMIDADE");
    }
    if (reading.alarmeCo2Alto) {
        alerts.push("LIMITE CO2");
    }
    if (reading.alarmeAlternador) {
        alerts.push("ALTERNADOR");
    }
    if (reading.alarmeErroRelogio) {
        alerts.push("ERRO RELOGIO");
    }
    if (reading.alarmeFluxoArTurbina1) {
        alerts.push("SENS FLUXO TURB 01");
    }
    if (reading.alarmeFluxoArTurbina2) {
        alerts.push("SENS FLUXO TURB 02");
    }
    if (reading.alarmePortaAberta) {
        alerts.push("PORTA ABERTA");
    }
    if (reading.alarmeFusivelSaidaC1) {
        alerts.push("FUS. INT. GRUPO 01");
    }
    if (reading.alarmeFusivelSaidaC2) {
        alerts.push("FUS. INT. GRUPO 02");
    }
    if (reading.alarmeFusivelSaidaC3) {
        alerts.push("FUS. INT. TURB MIN");
    }
    if (reading.alarmeFusivelSaidaC4) {
        alerts.push("FUS. INT. TURB MAX");
    }
    if (reading.alarmeRetroavisoVentilador01) {
        alerts.push("ACION. VENT 01");
    }
    if (reading.alarmeRetroavisoVentilador02) {
        alerts.push("ACION. VENT 02");
    }
    if (reading.alarmeRetroavisoVentilador03) {
        alerts.push("ACION. VENT 03");
    }
    if (reading.alarmeRetroavisoVentilador04) {
        alerts.push("ACION. VENT 04");
    }
    if (reading.alarmeRetroavisoVentilador05) {
        alerts.push("ACION. VENT 05");
    }
    if (reading.alarmeRetroavisoVentilador06) {
        alerts.push("ACION. VENT 06");
    }
    if (reading.alarmeRetroavisoTurbinaMaxima) {
        alerts.push("ACION. TURB 01 VEL MIN");
    }
    if (reading.alarmeRetroavisoTurbinaMinima) {
        alerts.push("ACION. TURB 01 VEL MAX");
    }
    if (reading.alarmeRetroavisoTurbinaC3) {
        alerts.push("ACION. TURB 02 VEL MIN");
    }
    if (reading.alarmeRetroavisoTurbinaC4) {
        alerts.push("ACION. TURB 02 VEL MAX");
    }

    return alerts;
}