import React, { Component } from 'react';
import { getNome } from './service/AuthService';

export class AppProfile extends Component {
    render() {
        return  (
            <div className="layout-profile">
                <div>
                    <img src="assets/layout/images/profile.png" alt="" />
                </div>                
                <span className="username p-link layout-profile-link"> {getNome()} </span>
            </div>
        );
    }
}