import React, { Component } from 'react';
import { EmpresasService } from '../../service/EmpresasService';
import { Loading } from '../Loading';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { Chips } from 'primereact/chips';
import { Maquinas } from '../maquinas/Maquinas';
import {Route} from 'react-router-dom';

export class EmpresaEdicao extends Component {

  constructor() {
    super();
    this.state = {
        emails: [],
    };
    this.empresasService = new EmpresasService();
  }

  componentDidMount() {    
    const { match: { params } } = this.props;    
    if (params.id === "0") {
      let empresa = {
        nome: "Nova empresa",
        ativo: true,
      };      
      this.setState({ empresa: empresa });      
    } else {      
      this.empresasService.getEmpresa(params.id)
        .then(data => this.setState({ empresa: data }));
      this.empresasService.getEmails(params.id)
        .then(data => this.setState({ emails: data.map(d => d.email) }));
    }    
  }  

  handleEditChange(event) {    
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;    
    const name = target.name;    
    let empresa = this.state.empresa;
    empresa[name] = value;
    this.setState({empresa: empresa});
  }

  handleSubmit(event) {    
    this.empresasService.saveEmpresa(this.state.empresa, this.state.emails)
    .then(sucesso => {
      if (sucesso) {      
        setTimeout(() => {
          window.history.back();
        }, 500);
      }
    });
    event.preventDefault();    
  }

  handleCancel() {
    window.history.back();    
  }

  getTitle() {
    if (this.state.empresa.id) {
      return "Edição de Empresas";
    } else {
      return "Nova Empresa";
    }
  }

  render() {
    if (this.state.empresa === undefined) {
      return <Loading/>
    }
    return (
      <span>    
        <div className="card card-w-title">
          <h1> {this.getTitle()} </h1>    
          <form className="p-grid" onSubmit={this.handleSubmit.bind(this)}>
              <div className="p-col-12">
                <div><label htmlFor="empresa-nome"> Nome: </label></div>
                <InputText id="empresa-nome" name="nome" value={this.state.empresa.nome} onChange={this.handleEditChange.bind(this)} required/>
              </div>
              <div className="p-col-12">
                <div><label htmlFor="empresa-emails"> Emails de alerta: </label></div>
                <Chips id="empresa-emails" name="emails" value={this.state.emails} onChange={(e) => this.setState({ emails: e.value })}/>
              </div>
              <div className="p-col-12">              
                <Checkbox id="empresa-ativo" name="ativo" label="Ativo" checked={this.state.empresa.ativo} onChange={this.handleEditChange.bind(this)}/>
                <label htmlFor="empresa-nome"> Ativo </label>
              </div>
              <div className="p-col p-justify-start">
                <Button label="Salvar" style={{marginRight: "10px"}}/>
                <Button label="Cancelar" type="button" onClick={this.handleCancel.bind(this)}/>                                      
              </div>
          </form>                        
        </div>
        {this.state.empresa.id &&
        <div className="card card-w-title">
          <h1> Máquinas da Empresa </h1>    
          <Route path="/empresa/:id" component={Maquinas} />
        </div>}
      </span>
    )
  }
}