import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { MotoristasService } from '../../service/MotoristasService';
import { Loading } from '../Loading';

const motoristaService = new MotoristasService();

export default function Motoristas() {

    const [motoristas, setMotoristas] = useState();

    useEffect(() => {
        motoristaService.getAll().then(setMotoristas);
    }, []);

    if (!motoristas) {
        return <Loading/>
    }

    return (
        <DataTable value={motoristas} responsive={false} header="Motoristas">
            <Column field="nome" header="Nome" body={row => formatNullField(row, 'nome')} filter={true}/>
            <Column field="login" header="Email" body={row => formatNullField(row, 'login')} filter={true}/>
            <Column field="nomeEmpresa" header="Empresa" body={row => formatNullField(row, 'nomeEmpresa')} filter={true}/>
            <Column header="Opções" body={lineOptions} style={{textAlign: 'center'}}/>
        </DataTable>
    );
}

function formatNullField(row, field) {
    const value = row[field];
    if (!value) {
        return '-';
    }
    return value;
}

function lineOptions(row) {
    return (
        <div className="p-grid p-nogutter p-justify-center">
            <Button icon="pi pi-pencil" onClick={() => window.location = `#/motoristas/${row.id}`} style={{ marginRight: '10px' }}/>        
        </div>
    );    
}