import React, { Component } from 'react';
import { Loading } from '../Loading';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { UsuariosService } from '../../service/UsuariosService';
import { DropdownEmpresas } from '../empresas/DropdownEmpresas';

export class UsuarioEdicao extends Component {

  constructor() {
    super();
    this.state = {};
    this.usuariosService = new UsuariosService();
  }

  componentDidMount() {    
    const { match: { params } } = this.props;    
    if (params.id === "0") {
      let usuario = {
        nome: "Novo Usuário",
        podeVerDetalhamentoLeituras: true,
        ativo: true,
      };            
      this.setState({ usuario: usuario, confirmacaoSenha: "" });      
    } else {            
      this.usuariosService.getUsuario(params.id)
        .then(data => this.setState({ usuario: data, confirmacaoSenha: data.senha }));
    }    
  }  

  handleEditChange(event) {    
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;    
    const name = target.name;
    let usuario = this.state.usuario;
    usuario[name] = value;
    // if (name === 'senha') {
    //   usuario.senhaCriptografada = false;
    // }
    this.setState({usuario: usuario});
  }

  handleSubmit(event) {
    event.preventDefault();    
    if (!this.isSenhaConfirmada()) {
      window.growl.showError('Erro', 'As senhas não são iguais');
      return;
    }
    this.usuariosService.saveUsuario(this.state.usuario).then(() => {
        setTimeout(() => {
          window.history.back();
        }, 500);
    }).catch(r => {
        console.log(r);
        window.growl.showError('Erro', r.response.data.title);
    });
  }

  handleCancel() {
    window.history.back();    
  }

  getTitle() {
    if (this.state.usuario.id) {
      return "Edição de Usuários";
    } else {
      return "Novo Usuário";
    }
  }

  setEmpresa(empresa) {
    let usuario = this.state.usuario;
    usuario.empresa = empresa;
    this.setState({ usuario: usuario });     
  }  

  isSenhaConfirmada() {
    return this.state.confirmacaoSenha === this.state.usuario.senha;
  }

  render() {
    if (this.state.usuario === undefined) {
      return <Loading/>
    }    

    const classConfSenha = this.isSenhaConfirmada() ? '' : 'p-error';

    return (
      <span>    
        <div className="card card-w-title">
          <h1> {this.getTitle()} </h1>    
          <form className="p-grid" onSubmit={this.handleSubmit.bind(this)}>

              <div className="p-col-12">
                <div><label htmlFor="usuario-nome"> Nome: </label></div>
                <InputText id="usuario-nome" name="nome" value={this.state.usuario.nome} onChange={this.handleEditChange.bind(this)} required/>
              </div>

              <div className="p-col-12">
                <div><label htmlFor="usuario-login"> Login: </label></div>
                <InputText id="usuario-login" name="login" value={this.state.usuario.login} onChange={this.handleEditChange.bind(this)} required/>
              </div>

              <div className="p-col-12">
                <div><label htmlFor="usuario-senha"> Senha: </label></div>
                <InputText id="usuario-senha" name="senha" value={this.state.usuario.senha} onChange={this.handleEditChange.bind(this)} type="password" required/>
              </div>

              <div className="p-col-12">
                <div><label htmlFor="usuario-conf-senha"> Confirme a senha: </label></div>
                <InputText id="usuario-conf-senha" name="confirmacao-senha" className={classConfSenha} value={this.state.confirmacaoSenha} onChange={e => this.setState({confirmacaoSenha: e.target.value})} type="password" required/>                
              </div>

              <div className="p-col-12">              
                <div><label htmlFor="usuario-empresa"> Empresa: </label></div>
                <DropdownEmpresas id="usuario-empresa" name="empresa.id" empresa={this.state.usuario.empresa} onChange={this.setEmpresa.bind(this)} location={this.props.location}/>
              </div>

              <div className="p-col-12">              
                <Checkbox id="detalhamento-leituras" name="podeVerDetalhamentoLeituras" checked={this.state.usuario.podeVerDetalhamentoLeituras} onChange={this.handleEditChange.bind(this)}/>
                <label htmlFor="detalhamento-leituras"> Pode ver o detalhamento de leituras </label>
              </div>

              <div className="p-col-12">              
                <Checkbox id="usuario-ativo" name="ativo" label="Ativo" checked={this.state.usuario.ativo} onChange={this.handleEditChange.bind(this)}/>
                <label htmlFor="usuario-ativo"> Ativo </label>
              </div>

              <div className="p-col p-justify-start">
                <Button label="Salvar" style={{marginRight: "10px"}}/>
                <Button label="Cancelar" type="button" onClick={this.handleCancel.bind(this)}/>                                      
              </div>

          </form>                        
        </div>
      </span>
    )
  }

}