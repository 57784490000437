import React, { useState, useLayoutEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { getNivel } from '../../service/AuthService'
import { getAlerts } from '../../service/LeiturasService';

export const TabelaLeituras = React.memo(({ leituras, onSelect }) => {
    if (!leituras.length) {
        return <div></div>
    }

    const isExportLocation = (window.location + '').includes('/export');
    const expandedRows = isExportLocation ? Object({[leituras[0].id]: true}) : null;
    const [width] = useWindowSize();

    let showV1 = leituras[0].versao === 1;

    if (showV1) {
        return (
            <DataTable value={leituras}
                        responsive={true} 
                        dataKey="id"
                        header="Leituras" 
                        scrollable={width > 640 && !isExportLocation}
                        scrollHeight="500px"
                        sortField="horaLeitura"
                        sortOrder={-1}
                        selectionMode={getNivel() === 'MASTER' && "single"}
                        onSelectionChange={onSelect}
                        className="leituras"
                        rowClassName={(data) => rowClass(data)}
                        rowExpansionTemplate={rowExpansionTemplate}
                        expandedRows={expandedRows}>
                <Column field="fieldHoraLeitura" header="Horário" sortable={false} className={isExportLocation && 'small-text'}/>
                <Column field="temperatura1" header="T1" sortable={false}/>
                <Column field="temperatura2" header="T2" sortable={false}/>
                <Column field="temperaturaMedia" header="T MED" sortable={false}/>
                <Column field="setpointTemperatura" header="T SP" sortable={false}/>
                <Column field="temperaturaExterna" header="TEXT" sortable={false}/>
                <Column field="umidade1" header="Um" sortable={false}/>
                <Column field="setpointUmidade" header="SP Um" sortable={false}/>
                <Column field="co2A" header="Co2" sortable={false}/>
                <Column field="setpointCo2" header="SP Co2" sortable={false}/>
                <Column field="fieldIgnicao" header="Ignição" sortable={false}/>
                <Column field="fieldAlternador" header="Alt." sortable={false}/>
                <Column field="fieldAutomatico" header="Man/ Aut" sortable={false}/>
            </DataTable>
        )
    } else {
        return (
            <DataTable value={leituras}
                        responsive={true} 
                        dataKey="id"
                        header="Leituras" 
                        scrollable={width > 640 && !isExportLocation}
                        scrollHeight="500px"
                        sortField="horaLeitura"
                        sortOrder={-1}
                        selectionMode={getNivel() === 'MASTER' && "single"}
                        onSelectionChange={onSelect}
                        className="leituras"
                        rowClassName={(data) => rowClass(data)}
                        rowExpansionTemplate={rowExpansionTemplate}
                        expandedRows={expandedRows}>
                <Column field="fieldHoraLeitura" header="Horário" sortable={false} className={isExportLocation ? 'small-text' : undefined}/>
                <Column field="temperatura1" header="T1" sortable={false}/>
                <Column field="temperatura2" header="T2" sortable={false}/>
                <Column field="temperaturaMedia" header="T MED" sortable={false}/>
                <Column field="setpointTemperatura" header="T SP" sortable={false}/>
                <Column field="temperaturaExterna" header="TEXT" sortable={false}/>
                <Column field="umidade1" header="UmA" sortable={false}/>
                <Column field="umidade2" header="UmB" sortable={false}/>
                <Column field="umidadeMedia" header="Um Med" sortable={false}/>
                <Column field="setpointUmidade" header="SP Um" sortable={false}/>
                <Column field="co2A" header="Co2A" sortable={false}/>
                <Column field="co2B" header="Co2B" sortable={false}/>
                <Column field="co2Media" header="Co2 Med" sortable={false}/>
                <Column field="setpointCo2" header="SP Co2" sortable={false}/>
                <Column field="fieldAlternador" header="Alt." sortable={false}/>
                <Column field="fieldAutomatico" header="Man/ Aut" sortable={false}/>
            </DataTable>
        )
    }
});

function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

function rowClass(data) {
    return {
        'row-alert': data.hasAlerts
    }
}

function rowExpansionTemplate(data) {
    const alerts = getAlerts(data);
    
    if (!alerts.length) {
        return;
    }

    return (
        <div>
            {alerts.join(', ')}
        </div>
    );
}