export class GrowlService {

  constructor(growl) {
    this.growl = growl;
  }

  showSuccess(title, msg) {
    this.growl.show({ severity: 'success', summary: title, detail: msg });
  }

  showInfo(title, msg) {
    this.growl.show({ severity: 'info', summary: title, detail: msg });
  }

  showWarn(title, msg) {
    this.growl.show({ severity: 'warn', summary: title, detail: msg });
  }

  showError(title, msg) {
    this.growl.show({ severity: 'error', summary: title, detail: msg });
  }

}