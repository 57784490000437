import React, { Component } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Loading } from '../Loading';
import { Checkbox } from 'primereact/checkbox';
import { MaquinasService } from '../../service/MaquinasService';

export class Maquinas extends Component {

  constructor() {
    super();
    this.state = {
      mostrarInativos: false,
      mostrarPreCadastros: false,
    };
    this.maquinasService = new MaquinasService();
  }

  componentDidMount() {
    const { match: { params } } = this.props;    
    let consulta;
    if (params.id) {
      consulta = this.maquinasService.getMaquinasPorEmpresa(params.id);
      this.setState({ idEmpresa: params.id });
    } else {
      consulta = this.maquinasService.getMaquinas();
    }
    consulta
      .then(data => data.sort((a, b) => a.id - b.id))
      .then(data => data.sort((a, b) => b.ativo - a.ativo))
      .then(data => this.setState({ maquinas: data }))      
  }  

  lineOptions(rowData, column) {
    return (
      <div className="p-grid p-nogutter p-justify-center">
        <Button icon="pi pi-pencil" onClick={() => window.location = `#/maquina/${rowData.id}`} style={{ marginRight: '10px' }}/>        
      </div>
    );    
  }

  lineStatus(rowData, column) {    
    if (rowData.ativo) {
      return <span> Ativo </span>
    }
    return <span style={{color: 'red'}} > Inativo </span>
  }

  lineEmpresa(rowData, column) {
    if (rowData.empresa) {
      return rowData.empresa.nome;
    }
    return '-';
  }

  handleMostrarInativos(event) {    
    this.setState({ mostrarInativos: event.checked });
  }

  handleMostrarPreCadastros(event) {    
    this.setState({ mostrarPreCadastros: event.checked });
  }

  getFilteredMaquinas() {
    let maquinas = this.state.maquinas;
    maquinas = maquinas.filter(e => e.ativo || this.state.mostrarInativos);
    maquinas = maquinas.filter(e => !e.empresa || !this.state.mostrarPreCadastros);
    return maquinas;
  }

  render() {

    const idEmpresa = this.state.idEmpresa;
    const pathNovo = '#/maquina/0' + (idEmpresa ? `?empresa=${idEmpresa}` : '');

    if (this.state.maquinas === undefined) {
      return <Loading/>
    }
    return (
      <div>
        <DataTable value={this.getFilteredMaquinas()} responsive={false} header="Veículos">          
          <Column field="descricao" header="Descrição" sortable={true} filter={true}/>                   
          <Column field="empresa.nome" body={this.lineEmpresa} header="Empresa" sortable={true} filter={true}/>          
          <Column field="apiToken" header="Token" sortable={true} style={{textOverflow: 'ellipsis', overflow: 'hidden'}} filter={true}/>
          <Column header="Status" body={this.lineStatus} style={{textAlign: 'center'}}/>
          <Column header="Opções" body={this.lineOptions} style={{textAlign: 'center'}}/>
        </DataTable>
        <div style={{display: 'flex', justifyContent: 'space-between', padding: '10px 0px'}}>
          <div>
            <Checkbox id="chk-inativos" checked={this.state.mostrarInativos} onChange={this.handleMostrarInativos.bind(this)}/>
            <label htmlFor="chk-inativos"> Mostrar inativos </label>
            <Checkbox id="chk-pre" checked={this.state.mostrarPreCadastros} onChange={this.handleMostrarPreCadastros.bind(this)}/>
            <label htmlFor="chk-pre"> Mostrar pré cadastros </label>
          </div>
          <Button label="Novo" icon="pi pi-plus" onClick={() => window.location = pathNovo}/>
        </div>
      </div>
    )
  }
}