import React, { Component } from 'react';
import { EmpresasService } from '../../service/EmpresasService';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Loading } from '../Loading';
import { Checkbox } from 'primereact/checkbox';

export class Empresas extends Component {

  constructor() {
    super();
    this.state = {
      mostrarInativos: false,
    };
    this.empresasService = new EmpresasService();
  }

  componentDidMount() {
    this.empresasService.getEmpresas()
      .then(data => data.sort((a, b) => a.id - b.id))
      .then(data => data.sort((a, b) => b.ativo - a.ativo))
      .then(data => this.setState({ empresas: data }))      
  }  

  lineOptions(rowData, column) {
    return <Button icon="pi pi-pencil" onClick={() => window.location = `#/empresa/${rowData.id}`}/>
  }

  lineStatus(rowData, column) {    
    if (rowData.ativo) {
      return <span> Ativo </span>
    }
    return <span style={{color: 'red'}} > Inativo </span>
  }

  handleMostrarInativos(event) {    
    this.setState({ mostrarInativos: event.checked });
  }

  getFilteredEmpresas() {
    return this.state.empresas.filter(e => e.ativo || this.state.mostrarInativos);
  }

  render() {
    if (this.state.empresas === undefined) {
      return <Loading/>
    }
    return (
      <div>
        <DataTable value={this.getFilteredEmpresas()} responsive={false} header="Empresas">          
          <Column field="nome" header="Nome" style={{width:'50%'}}/>          
          <Column header="Status" body={this.lineStatus} style={{textAlign: 'center', width:'25%'}}/>
          <Column header="Opções" body={this.lineOptions} style={{textAlign: 'center', width:'25%'}}/>
        </DataTable>
        <div style={{display: 'flex', justifyContent: 'space-between', padding: '10px 0px'}}>
          <div>
            <Checkbox checked={this.state.mostrarInativos} onChange={this.handleMostrarInativos.bind(this)}/>
            <label htmlFor="empresa-nome"> Mostrar inativos </label>
          </div>
          <Button label="Novo" icon="pi pi-plus" onClick={() => window.location = `#/empresa/0`}/>
        </div>
      </div>
    )
  }
}