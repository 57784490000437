import api from './Api';

export class MotoristasService {

    getAll() {
        return api.get('api/secured-motoristas/').then(res => res.data);
    }

    getByLogin(login) {
        return api.get('api/secured-motoristas/' + login).then(res => res.data);
    }

    getById(id) {
        return api.get('api/secured-motoristas/' + id).then(res => res.data);
    }

    resetPasswordMotorista(usuario) {
        return api.put('api/secured-motoristas/password/reset', { usuario });
    }

    updateMotorista(id, motorista) {
        return api.put('api/secured-motoristas/' + id, motorista);
    }

}