import React from 'react';
import { ProgressSpinner } from "primereact/progressspinner";

// componentHeight

export function Loading (props) {  

  if (!props.componentHeight) {
    const display = (props.isComponent ? "block" : "flex");
    const size = (props.isComponent ? "20px" : "50px");
    return (
      <div style={{display: display}}>
        <ProgressSpinner style={{width: size, height: size}} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"/>
      </div>
    )
  }

  return (
    <div style={{ display: 'flex', height: props.componentHeight, alignItems: 'center', border: '1px solid #0285e042', borderRadius: '6px'}}>
      <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"/>
    </div>
  )

}