import React, { Component, useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { hasAlert, LeiturasService } from '../../service/LeiturasService';
import { Loading } from '../Loading';
import { Column } from 'primereact/column';
import Moment from 'moment';
import { getNivel } from '../../service/AuthService';
import {Checkbox} from 'primereact/checkbox';
import { CardMonitoramento } from './CardMonitoramento';
import { RadioButton } from 'primereact/radiobutton';

export default function TabelaMonitoramento({ leituras }) {

  const [view, setView] = useState(2);

  useEffect(() => {
    if (view) {
      setView(parseInt(localStorage.getItem('monitoring-view') || 2));
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem('monitoring-view', view);
  }, [view]);

  const colEmpresaStyle = getNivel() !== 'MASTER' ? { display: "none" } : {};

  const ButtonsView = () => (
    <div style={{ marginBottom: '15px' }}>
      <div>
        <RadioButton inputId="view-cards" value={1} name="cards" onChange={e => setView(e.value)} checked={view === 1} />
        <label htmlFor="view-cards" className="p-radiobutton-label">Cards</label>
      </div>
      <div>
        <RadioButton inputId="view-table" value={2} name="table" onChange={e => setView(e.value)} checked={view === 2} />
        <label htmlFor="view-table" className="p-radiobutton-label">Tabela</label>
      </div>
    </div>
  );

  if (view === 1) return (
    <div>
      <ButtonsView/>
      <div className="p-grid">
        {leituras.map(l => <CardMonitoramento key={l.idMaquina} dados={l}/>)}
      </div>
    </div>
  );

  const leiturasDados = leituras.map(l => {
    return {
      ...l.leitura,
      idMaquina: l.idMaquina,
      nomeEmpresa: l.nomeEmpresa,
      nomeMaquina: l.nomeMaquina,
      hasAlerts: hasAlert(l.leitura),
    }
  });

  if (view === 2) return (
    <div>
      <ButtonsView/>
      <DataTable 
        value={leiturasDados} 
        header="Leituras" 
        responsive={true}
        rowClassName={data => ({ 'row-alert': data.hasAlerts })}
        onSelectionChange={onSelectionChange}
        selectionMode="multiple">
        <Column field="nomeMaquina" header="Veículo" sortable={false}/>
        <Column field="nomeEmpresa" header="Empresa" sortable={false} style={colEmpresaStyle}/>
        <Column field="horaLeitura" body={formatDateTime} header="Horário" sortable={false}/>
        <Column field="temperaturaMedia" body={montarColunasValor} header="TEMP" sortable={false}/>
        <Column field="temperaturaExterna" body={montarColunasValor} header="TEXT" sortable={false}/>
        <Column field="umidadeMedia" body={montarColunasValor} header="UM" sortable={false}/>
        <Column field="co2Media" body={montarColunasValor} header="CO2" sortable={false}/>
        <Column field="modoManual" body={montarColunasValor} header="Man/Aut" sortable={false}/>
      </DataTable>        
    </div>
  );

}

function formatDateTime(data, column) {    
  let tempo = new Date(data[column.field]);    
  return Moment(tempo).format('DD/MM/YYYY HH:mm');
}

function montarColunasValor(data, column) {
  const valor = data[column.field];    

  if (column.field === "modoManual") {
    return <div style={{textAlign: 'center'}}> {data.modoManual ? "MAN" : "AUT"} </div>
  }

  if (column.field === "temperaturaExterna") {
    return (
      <div> 
        <div style={{textAlign: 'center'}}> {valor < 70 ? valor : 0} </div> 
      </div>
    );
  }

  let maximo = null;
  
  if (column.field === "temperaturaMedia") {
    maximo = data.setpointTemperatura;
  } else if (column.field === "umidadeMedia") {
    maximo = data.setpointUmidade;
  } else if (column.field === "co2Media") {
    maximo = data.setpointCo2;
  }

  const width = (valor / maximo * 50) + '%';
  const color = ((valor / maximo * 100) >= 100 ? 'rgb(229, 3, 3)' : '#0388e5');
  
  return (
    <div> 
      <div style={{textAlign: 'center'}}> {valor} {maximo || maximo === 0 ? `/ ${maximo}` : ''} </div> 
      <div style={{backgroundColor: '#c8c8c8', borderRadius: '3px', overflow: 'hidden'}}>
        <div style={{backgroundColor: color, borderRadius: '3px', height: '3px', width: width}}/>
      </div>
    </div>
  );
}

function onSelectionChange(e) {
  const valor = e.value[0].idMaquina;
  window.location = "/#/maquina-resumo/" + valor;
}