import api from './Api';

export class UsuariosService {

  getUsuarios() {
    return api.get('api/usuarios')
      .then(res => res.data);
  }

  getUsuario(id) {
    return api.get(`api/usuarios/${id}`)
      .then(res => res.data);
  }

  getSelf() {
    return api.get(`api/usuarios/self`)
      .then(res => res.data);
  }

  saveUsuario(usuario) {
    return new Promise((resolve, reject) => {
        api.post('api/usuarios', usuario).then(res => {
            if (res.status === 200) {
                window.growl.showSuccess('Ok', 'Salvo com sucesso!');
                resolve(res);
            } else {
                reject(res);
            }
        }).catch(err => {        
            window.growl.showError('Erro', 'Ocorreu um erro ao salvar');
            console.log(err);
            reject(err);
        });
    });
  }

  getHistorico() {
    return api.get('api/usuarios/historico')
      .then(res => res.data);
  }

}